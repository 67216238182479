export default defineAppConfig({
    ui: {
        colors: {
            // @ts-ignore
            primary: 'gold',
            neutral: 'stone',
        },
        icons: {
            loading: 'material-symbols:progress-activity',
        },
    }
})